<template>
  <div>
    <order-image-modal ref="orderImageModal" />

    <b-modal
      id="booststrapmodal"
      :title="infoCard.name"
      centered
      :visible="visible"
      @hidden="handleModalHidden"
      size="lg"
    >
      <div class="modals-content">
        <div class="body">
          <b-table
            v-if="orders.items != undefined"
            class="order_table"
            responsive
            :fields="tableContent.fields"
            :items="orders.items"
            show-empty
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :emptyText="$t('InfoCardOrderListTable.TableEmptyText')"
          >
            <template #cell(comment)="data">
              {{
                data.item.comment ? data.item.comment : $t("Generic.NoComment")
              }}
            </template>
            <template #cell(sent)="data">
              {{
                data.item.sent
                  ? $t("Generic.Sent")
                  : $t("InfoCardOrderListTable.State.Waiting")
              }}
            </template>
            <template #cell(createdDate)="data">
              {{ format_datetime(data.item.createdDate) }}
            </template>
            <template #cell(confirmDate)="data">
              <span
                v-if="data.item.confirmDate.indexOf('0001-01-01T00:00:00') > -1"
              >
                {{ $t("InfoCardOrderListTable.NotApproved") }}
              </span>
              <span v-else-if="data.item.emptied">
                {{ $t("InfoCardOrderListTable.Emptied") }}
              </span>
              <span v-else>
                {{ $t("InfoCardOrderListTable.Approved") }}
              </span>
            </template>
            <template #cell(action)="data">
              <div class="flex-end">
                <icon-badge
                  @click="openImageModel(data.item)"
                  icon="ImageIcon"
                  v-b-tooltip.hover.top="
                    $t('InfoCardOrderListTable.ImageBtnText')
                  "
                />
                <icon-badge
                  v-if="
                    data.item.confirmDate.indexOf('0001-01-01T00:00:00') > -1
                  "
                  @click="handleCancelOrderClicked(data.item.id)"
                  class="mr-1 remove"
                  icon="XIcon"
                  v-b-tooltip.hover.top="$t('Generic.Cancel')"
                />
                <icon-badge
                  v-if="
                    data.item.confirmDate.indexOf('0001-01-01T00:00:00') ===
                      -1 && !data.item.emptied
                  "
                  @click="handleEmptiedSendClicked(data.item.id)"
                  class="mr-1"
                  icon="CheckIcon"
                  v-b-tooltip.hover.top="
                    $t('InfoCardOrderListTable.EmptiedBtnText')
                  "
                />
              </div>
            </template>
          </b-table>
          <div class="d-flex justify-content-center">
            <b-pagination
              v-if="orders.paging != undefined"
              v-model="currentPage"
              :total-rows="orders.paging.totalItems"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <div class="w-100">
            <b-button
              variant="primary"
              class="float-right"
              @click="handleModalHidden"
            >
              {{ $t("Generic.CloseBtn") }}
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import dragablemodal from "@/components/layout/dragablemodal.vue";
import { BModal, BTable, BButton, VBTooltip, BPagination } from "bootstrap-vue";
import IconBadge from "@/components/IconBadge.vue";
import OrdersService from "@/services/collection/orders-service";
import OrderImageModal from "@/components/modals/infoCards/OrderImageModal.vue";

export default {
  components: {
    dragablemodal,
    BPagination,
    BTable,
    BButton,
    IconBadge,
    BModal,
    OrderImageModal,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  async created() {
    this.service = new OrdersService();
  },
  data() {
    return {
      accountId: null,
      service: null,
      currentPage: 1,
      perPage: 15,
      visible: false,
      resolvePromise: undefined,
      rejectPromise: undefined,
      infoCard: {},
      orders: [],
      sortBy: "createdDate",
      sortDesc: true,
    };
  },
  methods: {
    async open(infoCard, accountId) {
      this.infoCard = infoCard;
      this.accountId = accountId;
      await this.fetchlistOrdersOnInfoCard();

      this.visible = true;

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    async handleCancelOrderClicked(orderId) {
      if (!confirm("Are you sure?")) return;
      await this.service.cancelOrderFor(orderId);
      this.fetchlistOrdersOnInfoCard();
    },

    async handleEmptiedSendClicked(orderId) {
      await this.service.markasEmptyForCollectorOrder(orderId, this.accountId);
      this.fetchlistOrdersOnInfoCard();
    },

    async fetchlistOrdersOnInfoCard() {
      let response = await this.service.listOrdersOnInfoCard(this.infoCard.id, {
        page: this.currentPage,
        perPage: this.perPage,
      });
      this.orders = response;
    },
    handleModalHidden() {
      this.orders = [];
      this.infoCard = {};
      this.visible = false;
    },
    handleCancel() {
      this.resolvePromise(false);
      this.handleModalHidden();
    },

    async openImageModel(order) {
      await this.$refs.orderImageModal.open(order);
    },
  },
  computed: {
    tableContent() {
      return {
        fields: [
          {
            key: "comment",
            label: this.$t("Generic.Comment"),
          },
          {
            key: "createdDate",
            label: this.$t("Generic.CreatedDate"),
            sortable: true,
          },
          {
            key: "sent",
            label: this.$t("Generic.Sent"),
            sortable: true,
          },
          {
            key: "confirmDate",
            label: this.$t("InfoCardOrderListTable.Fields.ConfirmDate"),
            sortable: true,
          },
          {
            key: "action",
            label: this.$t("InfoCardOrderListTable.Fields.Action"),
          },
        ],
      };
    },
  },
  watch: {
    async currentPage() {
      await this.fetchlistOrdersOnInfoCard({
        params: this.closedInvoicesParams,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
span {
  &.remove {
    cursor: pointer;
    &.hover-effect:hover {
      background-color: #cf4330;
    }
  }
}

.flex-end {
  display: flex;
  justify-content: space-evenly;
}
</style>
<style lang="scss">
.order_table {
  .table {
    td {
      border-top: unset !important;
      border-right: unset !important;
      border-left: unset !important;
      border-bottom: 1px solid #ebe9f1 !important;
    }
  }
}
</style>

