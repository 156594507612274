import { uuid } from "vue-uuid";
import BaseService from "../base/base.service";

export default class WasteCollectionOrdersService extends BaseService {
    constructor() {
        super('orders')
    }
    async listOrdersOnInfoCard(infoCardId, pageInfo = { }) {
        let response = await this.get(infoCardId, pageInfo);

        return response.data.data;
    }
    async createArray(orderDataArray) {
        for (const orderData of orderDataArray) {
            orderData.id = uuid.v4();

            var form_data = new FormData();

            if (orderData.files != undefined) {
                Array.from(orderData.files).forEach(file => {
                    form_data.append('files', file, file.name);
                });
            }

            for (var key in orderData) {
                if (key == "infoCard" || key == "files") continue;

                form_data.append(key, orderData[key]);
            }

            await this.post('/', form_data)
        }
    }

    async cancelOrderFor(orderId) {
        return await this.post('/cancel', { orderId: orderId });
    }

    async getForCollector(collectorId) {
        return await this.getAnonymous('/collector/' + collectorId);
    }
    async approveCollection(orderId) {
        return await this.postAnonymous('/approve/' + orderId, {});
    }
    async getImagesForCollectorOrder(orderId) {
        return await this.getAnonymous(orderId + '/images/');
    }
    async addImagesForCollectorOrder(orderId, data) {
        var form_data = new FormData();
        Array.from(data.files).forEach(file => {
            form_data.append('files', file, file.name);
        });
        for (var key in data) {
            if (key == "files") continue;

            form_data.append(key, data[key]);
        }
        return await this.postAnonymous(orderId + '/images/', form_data);
    }
    async removeImagesForCollectorOrder(orderId, imageId) {
        return await this.deleteAnonymous(orderId + '/images/' + imageId);
    }
    async markasEmptyForCollectorOrder(orderId, userid) {
        let uri = orderId + '/emptie/';
        if(userid != undefined) uri += userid;
        return await this.postAnonymous(uri);
    }
}