<template>
  <div>
    <confirm-modal ref="confirmModal" />

    <b-modal
      id="booststrapmodal"
      :title="$t('CreateOrderModal.Title')"
      centered
      :visible="visible"
      @hidden="handleModalHidden"
      :no-close-on-backdrop="true"
      :hide-header-close="true"
      size="lg"
    >
      <div class="create-order-modal order-list">
        <b-table responsive :fields="tableContent.fields" :items="orders">
          <template #cell(name)="data">
            <div class="d-flex">
              <b-avatar
                v-if="data.item.infoCard.coverPath"
                :src="baseUrl + '/images/' + data.item.infoCard.coverPath"
                class="mr-1"
              />
              <b-avatar
                v-else
                src="@/assets/images/container-no-image.jpg"
                class="mr-1"
              />
              <span class="d-flex flex-column">
                <strong>{{ data.item.infoCard.name }}
                  
                </strong>
                <small
                  >{{ $t("CreateOrderModal.Collector") }}
                  {{ data.item.infoCard.supplierContact.contactName }}</small
                >
              </span>
            </div>
          </template>
          <template #cell(address)="data">
            <small
              >{{ data.item.infoCard.location.address }},
              {{ data.item.infoCard.location.zip }}
              {{ data.item.infoCard.location.city }}</small
            >
          </template>
          <template #cell(options)="data">
            <div class="d-flex flex-column">
              <b-form-textarea
                :placeholder="$t('Generic.Comment')"
                v-model="data.item.comment"
              />
              <weekday-picker
                v-if="data.item.infoCard.pickupDays.length > 0"
                class="weekday-picker"
                :value="data.item.infoCard.pickupDays"
                :disabled="true"
              />
              <span class="no-weekday-to-show-text" v-else>{{
                $t("CreateOrderModal.FastPickup")
              }}</span>
            </div>
          </template>

          <template #cell(images)="data">
            <input type="file" @change="setFiles($event, data)" multiple />
          </template>
        </b-table>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            class="float-right"
            :disabled="!canSubmit || submitting"
            @click="handleSubmit"
          >
            {{
              submitting
                ? $t("CreateOrderModal.Ordering")
                : $t("CreateOrderModal.Order")
            }}
          </b-button>
          <b-button
            variant="light"
            class="float-right mr-2"
            @click="handleCancel"
            :disabled="submitting"
          >
            {{ $t("Generic.Cancel") }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BButton, BAvatar, BTable, BFormTextarea } from "bootstrap-vue";
import FlexTable from "@/components/flex-table/FlexTable.vue";
import WeekdayPicker from "@/components/forms/WeekdayPicker.vue";
import WasteCollectionOrdersService from "@/services/collection/waste-collection-orders.service";
import { mapActions } from "vuex";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";

export default {
  components: {
    BModal,
    BButton,
    FlexTable,
    BAvatar,
    BTable,
    BFormTextarea,
    WeekdayPicker,
    ConfirmModal,
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_APIURL,
      visible: false,
      submitting: false,
      resolvePromise: undefined,
      rejectPromise: undefined,
      orders: [],
      infoCards: [],
    };
  },
  methods: {
    ...mapActions({ fetchInfoCard: "infoCards/fetchSingleOfType" }),
    open(infoCards) {
      this.visible = true;
      this.orders = infoCards.map((i) => {
        return { infoCardId: i.id, infoCard: i, comment: "" };
      });
      this.infoCards = infoCards;
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    setFiles(event, data) {
      data.item.files = event.target.files;
    },
    handleModalHidden() {
      this.visible = false;
      this.infoCards = [];
    },
    async handleSubmit() {
      try {
        this.submitting = true;
        const service = new WasteCollectionOrdersService();
        await service.createArray(this.orders);
        for (var order of this.orders)
          this.fetchInfoCard({
            id: order.infoCardId,
            type: "ContainerInfoCard",
          });
        this.handleModalHidden();
        return this.resolvePromise(true);
      } finally {
        this.submitting = false;
      }
    },
    handleCancel() {
      this.resolvePromise(false);
      this.handleModalHidden();
    },
  },
  computed: {
    tableContent() {
      return {
        fields: [
          {
            key: "name",
            label: this.$t("Generic.Name"),
            sortable: false,
          },
          {
            key: "address",
            label: this.$t("Generic.Address"),
            sortable: false,
          },
          {
            key: "options",
            label: this.$t("CreateOrderModal.Fields.Options"),
            sortable: false,
          },
          {
            key: "images",
            label: this.$t("CreateOrderModal.Fields.Image"),
          },
        ],
      };
    },
    canSubmit() {
      return true;
    },
  },
};
</script>
<style lang="scss">
.modal-lg,
.modal-xl {
  max-width: 1035px;
}
@media (max-width: 648px) {
  .create-order-modal.order-list {
    .table-responsive {
      overflow-x: hidden;
    }
    .table {
      display: flex;
      thead {
        display: none;
      }
      tbody {
        display: flex;
        flex-direction: column;
        width: 100%;
        tr {
          display: flex;
          flex-direction: column;
          border-bottom: 1px solid #ebe9f1;
          padding: 15px 0;
          td {
            border: none;
            padding: 0;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.weekday-picker {
  margin-top: 10px;
}
.no-weekday-to-show-text {
  font-weight: bold;
  color: #30cf84;
}
</style>