var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('create-order-modal',{ref:"createOrderModal"}),_c('order-same-date-modal',{ref:"orderSameDateModal"}),_c('infocard-order-modal',{ref:"infocardOrderModal"}),_c('div',{staticClass:"wrap",class:{ grid: _vm.showAsGrid }},[(_vm.infoCards.length == 0)?_c('h3',{staticClass:"text-muted text-center"},[_vm._v(" "+_vm._s(_vm.$t("Generic.NoOrderedInfoCards"))+" ")]):_vm._e(),_c('div',{staticClass:"layout_selectors"},[_c('feather-icon',{attrs:{"size":_vm.showAsGrid ? '28' : '16',"icon":"GridIcon"},on:{"click":function($event){return _vm.changeLayout('grid')}}}),_c('feather-icon',{attrs:{"size":_vm.showAsGrid ? '20' : '28',"icon":"AlignJustifyIcon"},on:{"click":function($event){return _vm.changeLayout('list')}}})],1),_vm._l((_vm.infoCards),function(card){return _c('b-card',{key:card.id,attrs:{"no-body":""}},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"cardHeader"},[(card.coverPath != null)?_c('b-avatar',{attrs:{"src":_vm.baseUrl + '/images/' + card.coverPath}}):_c('b-avatar',{attrs:{"src":require("@/assets/images/container-no-image.jpg")}}),_c('b-card-title',[_c('span',{staticClass:"flex name"},[_c('span',[_vm._v(_vm._s(card.name))])])]),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
              card.supplierContact == undefined ||
              card.supplierContact.contactEmail == null
                ? _vm.$t('InfoCardOrderListTable.EmptySupplierEmail')
                : ''
            ),expression:"\n              card.supplierContact == undefined ||\n              card.supplierContact.contactEmail == null\n                ? $t('InfoCardOrderListTable.EmptySupplierEmail')\n                : ''\n            ",modifiers:{"hover":true,"top":true}}],class:{
              contactEmail:
                card.supplierContact == undefined ||
                card.supplierContact.contactEmail == null,
              selected: _vm.infoCardSelected(card),
            },attrs:{"variant":_vm.infoCardSelected(card) || _vm.showAsGrid
                ? 'primary'
                : 'outline-primary',"size":"sm"},on:{"click":function($event){return _vm.toggleInfoCardSelected(card)}}},[_c('feather-icon',{attrs:{"size":"20","icon":_vm.getIconForSelectOrder(card)}})],1)],1),_c('div',{staticClass:"order_container"},[(card.supplierContact != null)?_c('strong',[_c('feather-icon',{attrs:{"size":'16',"icon":"TruckIcon"}}),_vm._v(" - "+_vm._s(card.supplierContact.contactName)+" ")],1):_vm._e(),_c('div',[_c('span',[_vm._v(" "+_vm._s(_vm.$t("InfoCardOrderListTable.LastOrderText"))+" "+_vm._s(_vm.getDateSinceTextFromDateTime(card))+" ")]),_c('b-button',{staticClass:"show-orders",attrs:{"size":"sm"},on:{"click":function($event){return _vm.toggleorderInfoCardSelected(card)}}},[_vm._v(" "+_vm._s(_vm.$t("InfoCardOrderListTable.ShowOrders"))+" ")])],1)])])])})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }