<template>
  <div>
    <create-order-modal ref="createOrderModal" />
    <template v-if="isLoadingLocations">
      <div class="d-flex justify-content-center mt-2 mb-2">
        <b-spinner />
      </div>
    </template>
    <template v-else-if="hasNoLocations">
      <b-card>
        <div class="d-flex justify-content-center flex-column">
          <h3 class="text-center text-muted">
            {{ T("Web.Generic.Locations.NoLocations") }}
          </h3>
        </div>
      </b-card>
    </template>
    <template v-else-if="activeLocation != undefined">
      <location-picker-bar
        @infocardcreated="pullInfocards"
        :locations="locations"
        v-model="activeLocation"
      >
        <template #right>
          <div class="d-flex justify-content-end createorder-container">
            <div class="order-count mr-1">
              <span> {{ $t("InfoCardOrderListTable.OverviewTitle") }}</span>
              <span
                >{{ selectedInfoCardsCount }}
                {{ $t("InfoCardOrderListTable.SelectedOrdersText") }}</span
              >
            </div>
            <b-button
              variant="secondary"
              class="btn-dark"
              @click="openCreateOrderModal"
              :disabled="selectedInfoCardsCount === 0"
            >
              {{ $t("InfoCardOrderListTable.CrateOrdersBtn") }}
            </b-button>
          </div>
        </template>
      </location-picker-bar>
      <div
        class="d-flex justify-content-center mt-2 mb-2"
        v-if="isLoadingInfoCards"
      >
        <b-spinner />
      </div>
      <info-card-order-list-table
        ref="orderList"
        :location="activeLocation"
        :info-cards="infoCards"
        @toggle-info-card-selected="handleToggleInfoCardSelected"
        :selected-info-cards="selectedInfoCards"
      />
    </template>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import {
  BSpinner,
  BCard,
  BButton,
  BRow,
  BCol,
  BTabs,
  BTab,
} from "bootstrap-vue";
import infoCardOrderListTable from "@/components/tables/infoCards/infoCardOrderListTable.vue";
import LocationPickerBar from "@/components/LocationPickerBar.vue";
import CreateOrderModal from "@/components/modals/infoCards/CreateOrderModal.vue";
export default {
  components: {
    infoCardOrderListTable,
    BSpinner,
    BCard,
    BButton,
    BRow,
    BCol,
    BTabs,
    BTab,
    LocationPickerBar,
    CreateOrderModal,
  },
  async created() {
    await this.fetchLocations();
    this.activeLocation = this.locations[0];
  },
  data() {
    return {
      activeLocation: undefined,
      selectedInfoCards: [],
    };
  },
  computed: {
    ...mapGetters({
      locations: "locationsOld/list",
      isLoadingLocations: "locationsOld/isLoading",
      isLoadingInfoCards: "locationsOld/isLoading",
      infoCards: "infoCards/list",
    }),
    hasNoLocations() {
      return !(this.locations && this.locations.length > 0);
    },
    selectedInfoCardsCount() {
      return this.selectedInfoCards?.length ?? 0;
    },
  },
  methods: {
    ...mapActions({
      fetchLocations: "locationsOld/fetchList",
      fetcInfoCards: "infoCards/fetchListOfType",
    }),
    handleToggleInfoCardSelected(infoCard) {
      const existingRecord = this.selectedInfoCards.find(
        (i) => i.id == infoCard.id
      );
      if (existingRecord)
        this.selectedInfoCards.splice(
          this.selectedInfoCards.indexOf(existingRecord),
          1
        );
      else this.selectedInfoCards.push(infoCard);
    },
    async openCreateOrderModal() {
      const isSuccess = await this.$refs.createOrderModal.open(
        this.selectedInfoCards
      );

      if (isSuccess) {
        this.selectedInfoCards = [];
      }
    },
    pullInfocards() {
      this.fetcInfoCards({
        include: ["orderbyOrders"],
        params: { locationId: this.activeLocation.id },
        type: "ContainerInfoCard",
      });
    },
  },
  watch: {
    activeLocation(newValue, oldValue) {
      if (newValue == oldValue) return;
      this.pullInfocards();
    },
  },
};
</script>
<style lang="scss" scoped>
.order-count {
  display: flex;
  flex-direction: column;
  align-items: end;
  span:first-child {
    display: flex;
    flex-direction: column;
    font-weight: bold;
  }
}

@media (max-width: 648px) {
  .createorder-container {
    flex-direction: column;
  }
}
</style>