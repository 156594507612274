<template>
  <div>
    <b-modal
    id="booststrapmodal"
      :title="$t('OrderSameDateModal.Title')"
      centered
      :visible="visible"
      @hidden="handleModalHidden"
    >
      <!-- Body -->

      <b-row>
        <b-col cols="12">
          {{ $t("OrderSameDateModal.TopText") }}
        </b-col>
        <br />
        <br />
        <b-col cols="12">
          {{ $t("OrderSameDateModal.BottomText") }}
        </b-col>
      </b-row>

      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" class="float-right" @click="handleSubmit">
            {{ $t("Generic.Okay") }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
  },
  data() {
    return {
      visible: false,
      resolvePromise: undefined,
      rejectPromise: undefined,
    };
  },
  methods: {
    open() {
      this.visible = true;
    },
    handleModalHidden() {
      this.visible = false;
    },
    handleSubmit() {
      this.handleModalHidden();
    },
    handleCancel() {
      this.handleModalHidden();
    },
  },
};
</script>