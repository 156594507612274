<template>
  <div>
    <create-order-modal ref="createOrderModal" />
    <order-same-date-modal ref="orderSameDateModal" />
    <infocard-order-modal ref="infocardOrderModal" />
    <div :class="{ grid: showAsGrid }" class="wrap">
      <h3 class="text-muted text-center" v-if="infoCards.length == 0">
        {{ $t("Generic.NoOrderedInfoCards") }}
      </h3>

      <div class="layout_selectors">
        <feather-icon
          :size="showAsGrid ? '28' : '16'"
          icon="GridIcon"
          @click="changeLayout('grid')"
        />
        <feather-icon
          :size="showAsGrid ? '20' : '28'"
          icon="AlignJustifyIcon"
          @click="changeLayout('list')"
        />
      </div>

      <b-card v-for="card in infoCards" :key="card.id" no-body>
        <div class="header">
          <div class="cardHeader">
            <b-avatar
              v-if="card.coverPath != null"
              :src="baseUrl + '/images/' + card.coverPath"
            />
            <b-avatar v-else src="@/assets/images/container-no-image.jpg" />
            <b-card-title>
              <span class="flex name">
                <span>{{ card.name }}</span>
              </span>
            </b-card-title>

            <b-button
              :class="{
                contactEmail:
                  card.supplierContact == undefined ||
                  card.supplierContact.contactEmail == null,
                selected: infoCardSelected(card),
              }"
              v-b-tooltip.hover.top="
                card.supplierContact == undefined ||
                card.supplierContact.contactEmail == null
                  ? $t('InfoCardOrderListTable.EmptySupplierEmail')
                  : ''
              "
              :variant="
                infoCardSelected(card) || showAsGrid
                  ? 'primary'
                  : 'outline-primary'
              "
              @click="toggleInfoCardSelected(card)"
              size="sm"
            >
              <feather-icon size="20" :icon="getIconForSelectOrder(card)" />
            </b-button>
          </div>
          <div class="order_container">
            <strong v-if="card.supplierContact != null">
              <feather-icon :size="'16'" icon="TruckIcon" /> -
              {{ card.supplierContact.contactName }}
            </strong>
            <div>
              <span>
                {{ $t("InfoCardOrderListTable.LastOrderText") }}
                {{ getDateSinceTextFromDateTime(card) }}
              </span>
              <b-button
                class="show-orders"
                @click="toggleorderInfoCardSelected(card)"
                size="sm"
              >
                {{ $t("InfoCardOrderListTable.ShowOrders") }}
              </b-button>
            </div>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BSpinner,
  BRow,
  BCardTitle,
  BTable,
  BAvatar,
  BFormCheckbox,
  BCol,
  BButton,
  BBadge,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import IconBadge from "@/components/IconBadge.vue";
import { VBTooltip } from "bootstrap-vue";
import OrdersService from "@/services/collection/orders-service";

import InfocardOrderModal from "@/components/modals/infoCards/InfocardOrderModal.vue";
import CreateOrderModal from "@/components/modals/infoCards/CreateOrderModal.vue";
import OrderSameDateModal from "@/components/modals/infoCards/OrderSameDateModal.vue";
import authHelper from "@/auth";

export default {
  props: {
    location: {
      required: true,
    },
    infoCards: {
      required: true,
    },
    selectedInfoCards: {
      required: true,
    },
  },
  components: {
    BCard,
    BSpinner,
    BRow,
    BCardTitle,
    BTable,
    BAvatar,
    BFormCheckbox,
    BCol,
    BButton,
    BBadge,
    IconBadge,
    VBTooltip,
    CreateOrderModal,
    OrderSameDateModal,
    InfocardOrderModal,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  mounted() {
    this.service = new OrdersService();
    let userid = authHelper.getAccountId();
    if (userid != undefined) this.accountId = userid;

    if (localStorage.infoCardOrderListLayout != undefined)
      this.layout = localStorage.infoCardOrderListLayout;
  },
  data() {
    return {
      layout: "grid",
      service: null,
      baseUrl: process.env.VUE_APP_APIURL,
      forcedSend: [],
      accountId: "00000000-0000-0000-0000-000000000000",
    };
  },
  methods: {
    ...mapActions({
      fetcInfoCards: "infoCards/fetchListOfType",
      fetchInfoCard: "infoCards/fetchSingleOfType",
    }),
    changeLayout(val) {
      this.layout = val;
      localStorage.infoCardOrderListLayout = val;
    },
    getIconForSelectOrder(infocard) {
      let isSelected = this.infoCardSelected(infocard);

      return isSelected ? "CheckIcon" : "PlusIcon";
    },
    infoCardSelected(infoCard) {
      return this.selectedInfoCards.find((i) => i.id == infoCard.id) != null;
    },
    toggleInfoCardSelected(infoCard) {
      if (
        infoCard.supplierContact == undefined ||
        infoCard.supplierContact.contactEmail == null
      )
        return;

      const existingRecord = this.selectedInfoCards.find(
        (r) => r.id == infoCard.id
      );
      if (existingRecord == undefined && this.getDateSince(infoCard) == 0) {
        this.$refs.orderSameDateModal.open();
      }
      this.$emit("toggle-info-card-selected", infoCard);
    },
    async toggleorderInfoCardSelected(infoCard) {    
      await this.$refs.infocardOrderModal.open(infoCard, this.accountId);
    },
    handleModalHidden() {
      this.$refs.infocardOrderModal.hide();
    },
    getDateSince(card) {
      const collected = card.lastOrderDate;
      if (collected == null) return -1;
      const collectedDate = new Date(collected);

      const oneDay = 24 * 60 * 60 * 1000;
      const today = new Date();
      const diffDays = Math.round(Math.abs((today - collectedDate) / oneDay));

      return diffDays;
    },
    getDateSinceTextFromDateTime(card) {
      const diffDays = this.getDateSince(card);

      if (diffDays == -1) return this.$t("InfoCardOrderListTable.Never");

      if (diffDays == 0) return this.$t("InfoCardOrderListTable.Today");
      if (diffDays == 1)
        return "1 " + this.$t("InfoCardOrderListTable.DaysAgo");
      return `${diffDays} ` + this.$t("InfoCardOrderListTable.DaysAgo");
    },
    async openCreateOrderModal() {
      const isSuccess = await this.$refs.createOrderModal.open(
        this.selectedInfoCards
      );

      if (isSuccess) this.selectedInfoCards = [];
    },
    isForced(id) {
      return this.forcedSend.indexOf(id) > -1;
    },
  },
  computed: {
    ...mapGetters({
      loading: "infoCards/isLoading",
    }),
    showAsGrid() {
      return this.layout == "grid";
    },
    selectedInfoCardsCount() {
      return this.selectedInfoCards?.length ?? 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.contactEmail {
  opacity: 0.4;
}
.layout_selectors {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: end;
  padding: 20px;

  svg {
    cursor: pointer;
    margin-right: 10px;
  }
}
.grid {
  display: flex;
  flex-wrap: wrap;

  .card {
    width: 18% !important;
    min-width: 250px;
    margin-bottom: 30px !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .cardHeader {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 !important;
    align-items: center !important;
    .card-title {
      position: absolute;
      color: #fff !important;
      text-shadow: -1px -1px 5px rgb(0 0 0);
      left: 20px;
      font-size: 1.4rem;
      bottom: 20px;
    }
    button {
      &.selected {
        svg {
          color: #fff !important;
        }
        border-color: #407e3d !important;
        background-color: #407e3d !important;
      }
      svg {
        color: #6e6b7b !important;
      }
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: -20px;
      right: 20px;
      border-radius: 50%;
      width: 45px;
      height: 45px;
      border-color: #f3f2f7 !important;
      background-color: #f3f2f7 !important;
      box-shadow: unset !important;
    }
  }

  .b-avatar {
    width: 100% !important;
    border-radius: unset !important;
    height: 250px !important;
  }

  .order_container {
    padding: 2.5rem 1.5rem 1rem 1.5rem !important;
  }
}

.order_container {
  flex-direction: column;
  div {
    padding: 0 !important;
  }
}

.wrap {
  margin-bottom: 20px;
  .card {
    .header {
      div {
        &.cardHeader {
          border-bottom: 1px solid #ccc;
        }
        display: flex;
        justify-content: space-between;
        padding: 1.5rem;
        .card-title {
          margin-bottom: 0px;
        }
      }
    }
  }
}
@media (max-width: 648px) {
  .grid {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    .card {
      width: 100% !important;
      margin-bottom: 30px !important;
      margin-left: unset !important;
      margin-right: unset !important;
    }
  }
}
</style>
